.rich-text-block {
  @apply format max-w-none;
  p {
    @apply max-w-richtext;
  }
  h4 {
    @apply max-w-richtext;
  }
  h5 {
    @apply max-w-richtext;
  }
  h6 {
    @apply max-w-richtext;
  }
  ul {
    @apply max-w-richtext;
  }
  ol {
    @apply max-w-richtext;
  }
  /* avoid duplicate list item markers in nested lists */
  :global(.nestedListItem)::marker {
    content: '';
  }
  blockquote {
    @apply my-10 max-w-[840px] font-serif text-2xl font-normal italic !leading-normal text-gray-500 lg:text-3xl;
    &:before {
      @apply content-none;
    }
  }
  :global(.lexical-table) {
    @apply my-7 w-full table-auto !border-separate border-spacing-[1px] rounded-lg bg-gray-50 text-base shadow-sm;
    tr {
      border: none;
    }
    th,
    td {
      @apply !border-none bg-white !p-3 shadow-[0_0_0_1px] shadow-gray-200;
    }
    th {
      @apply bg-gray-50;
    }
    tr:first-child {
      th:first-child,
      td:first-child {
        @apply rounded-tl-lg;
      }
      th:last-child,
      td:last-child {
        @apply rounded-tr-lg;
      }
    }
    tr:last-child {
      th:first-child,
      td:first-child {
        @apply rounded-bl-lg;
      }
      th:last-child,
      td:last-child {
        @apply rounded-br-lg;
      }
    }
    p {
      @apply my-0;
    }
  }
}
