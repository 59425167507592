/* control space between blocks */
.gutter {
  @apply bg-white py-12 md:py-24;
}
/* move adjacent blocks of the same type closer together */
.gutter:not(.tainted-gutter) + .gutter:not(.tainted-gutter),
.tainted-gutter + .tainted-gutter {
  @apply pt-0 md:pt-0;
}
/* taint block background */
.tainted-gutter {
  @apply bg-gray-50;
}
.tainted-gutter:not(:first-of-type) {
  @apply border-t;
}
/* avoid double border when gutter sits right before footer / marketing notice */
.tainted-gutter:not(:last-child) {
  @apply border-b;
}
/* allow tainted blocks to follow each other without border inbetween*/
.tainted-gutter + .tainted-gutter {
  @apply -mt-px border-t-0;
}
