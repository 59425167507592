.thumb {
  @apply size-6 rounded-full border border-solid border-gray-200 bg-white shadow-sm;
}

/* NOTE: vendor prefixed rules can not be grouped */
.slider {
  /* Safari, Chrome, Edge */
  input[type='range']::-webkit-slider-thumb {
    @apply thumb;
  }

  /* Firefox */
  input[type='range']::-moz-range-thumb {
    @apply thumb;
  }

  /* IE */
  input[type='range']::-ms-thumb {
    @apply thumb;
  }
}
