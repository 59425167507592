.rich-text-base {
  @apply break-words;
  a {
    @apply text-primary hover:text-primary-900 focus:outline-primary;
  }
  p {
    @apply my-5;
  }
  h1 {
    @apply text-4xl font-normal text-gray lg:text-5xl;
  }
  h2 {
    @apply text-4xl font-normal text-gray lg:text-5xl;
  }
  h3 {
    @apply text-3xl font-normal text-gray lg:text-4xl;
  }
  h4 {
    @apply text-2xl font-normal text-gray lg:text-3xl;
  }
  h5 {
    @apply text-xl font-normal text-gray lg:text-2xl;
  }
  h6 {
    @apply text-lg font-normal text-gray lg:text-xl;
  }
  :global(.button) {
    @apply button-base bg-primary text-white;
  }
  :global(.button-outline) {
    @apply button-base;
  }
}
.button-base {
  @apply me-2 inline-flex items-center whitespace-nowrap rounded-lg border border-primary px-6 py-3 text-base no-underline transition-all duration-200 hover:border-primary-800 hover:bg-primary-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-primary-300;
  svg {
    @apply ml-2;
  }
  :global(.icon-arrow-right) {
    @apply mt-0.5;
  }
}
